/* Dependencies */
import { CountUp } from 'countup.js';
import axios from 'axios';

import eachElement from 'Utils/eachElement.js';
import errorHandler from 'Utils/errorHandler.js';


export default {
	/* Global vars */

	/* Object of the statistics; key is stat name, value is default value */
	/* Some, but not all, values are loaded from the API in socket.js */
	numbers: {
		totalDeployments: 0,
		totalComparisons: 0,
		totalDataBackedUp: 0,
		totalMetadataComponentsDeployed: 0,
		totalDataRecordsDeployed: 0,
		totalTestsRun: 0,
		totalHoursSaved: 0,
		productivityIncrease: 0,
		totalReleases: 0,
		averageResponseTime: 0,
		averageRating: 0,
		uservoiceRequests: 0,
		prsMerged: 0,
		successRate: 0,
	},

	/* Observer to check whether each display location is currently visible */
	observer: null,


	/* Methods */

	init() {
		/* Fetch initial numbers */
		axios.get('/data/trust.json')
			.then(response => {
				this.numbers = response.data;

				/* Set up observer to check whether each counter is visible */
				this.observer = new IntersectionObserver((entries => {
					for (const entry of entries) {
						if (entry.isIntersecting) {
							entry.target.countup?.start();
						}
					}
				}), {
					root: null,
					rootMargin: '0px 0px -50px',
					threshold: 0.5,
				});

				/* For each element, set up a CountUp object and observer */
				eachElement('[data-number]', element => {
					const number = element.dataset.number;

					const options = {
						decimalPlaces: 0,
						formattingFn: value => {
							// Customise number formatting based on variable name (fragile?)
							switch (number) {
								case 'averageResponseTime':
									return new Date(1000 * value).toISOString().slice(11, 19);

								default:
									return value.toLocaleString('en');
							}
						},
					};

					/* Add countup instance to element */
					element.countup = new CountUp(element, this.numbers[number], options);

					this.observer.observe(element);
				});
			})
			.catch(errorHandler);
	},

	/* Update all CountUp objects for the given list of new stats */
	updateAppStats(appStats) {
		for (const appStat of Object.entries(appStats)) {
			this.updateStat(appStat);
		}
	},

	incrementStat(appStatName) {
		const endValue = document.querySelector(`[data-number="${appStatName}"]`).countup?.endVal || 0;
		this.updateStat([appStatName, endValue + 1]);
	},

	/* Update the number for a given CountUp object, or do nothing if no CountUp object exists yet */
	updateStat(appStat) {
		const [statName, statValue] = appStat;

		eachElement(`[data-number="${statName}"]`, element => {
			const cu = element.countup;
			/* Set counter value to new stat */
			/* If the animation has started, update the value appropriately */
			/* If it hasn't begun yet (has not been scrolled into view), update kludgily */
			if (cu.startTime) {
				// Start the start as the previous end - no idea why it doesn't do this automatically
				cu.startVal = cu.endVal;
				cu.update(statValue);
			} else {
				cu.endVal = statValue;
			}

			/* Hack to force IntersectionObserver to refresh, in order to trigger .start() properly */
			this.observer.unobserve(element);
			this.observer.observe(element);
		});
	},
};
